import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import Button from '../components/button'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      firstname: '',
      lastname: '',
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })

    if ([e.target.name] == 'firstname') {
      this.setState({
        subject:
          'BKSounds Website Form Submission - ' +
          e.target.value +
          ' ' +
          this.state.lastname,
      })
    }
    if ([e.target.name] == 'lastname') {
      this.setState({
        subject:
          'BKSounds Website Form Submission - ' +
          this.state.firstname +
          ' ' +
          e.target.value,
      })
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        subject: this.state.subject,
        ...this.state,
      }),
    })
      .then(() =>
        navigate(form.getAttribute('action'), { state: { ...this.state } })
      )
      .catch(error => alert(error))
  }

  render() {
    const ButtonContainer = styled.div`
      margin-top: 1em;
      button {
        width: 100%;
        padding: 0.75em;
        margin-bottom: 1em;
      }
    `
    const FormLabel = styled.label`
      color: #333;
    `

    return (
      <form
        name="contact-form"
        method="post"
        action="/thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
        className={
          this.props.style ? this.props.style : 'contact-form-default-style'
        }
        style={{ margin: '0' }}
      >
        <div className="form-group">
          <div className="row">
            <div className="col-12 col-lg-6">
              {this.props.showLabels ? (
                <FormLabel htmlFor="firstname">First Name</FormLabel>
              ) : null}
              <input
                type="text"
                name="firstname"
                className="form-control"
                id="firstname"
                placeholder={this.props.showPlaceHolders ? 'First Name' : ''}
                required
                onChange={this.handleChange}
              />
            </div>
            <div className="col-12 col-lg-6">
              {this.props.showLabels ? (
                <FormLabel htmlFor="lastname">Last Name</FormLabel>
              ) : null}
              <input
                type="text"
                name="lastname"
                className="form-control"
                id="lastname"
                placeholder={this.props.showPlaceHolders ? 'Last Name' : ''}
                required
                onChange={this.handleChange}
              />
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="row">
            <div className="col-12 col-lg-6">
              {this.props.showLabels ? (
                <FormLabel htmlFor="email">Email address</FormLabel>
              ) : null}
              <input
                type="email"
                name="email"
                id="email"
                className="form-control"
                placeholder={this.props.showPlaceHolders ? 'Email address' : ''}
                required
                onChange={this.handleChange}
              />
            </div>
            <div className="col-12 col-lg-6">
              {this.props.showLabels ? (
                <FormLabel htmlFor="phone">Phone</FormLabel>
              ) : null}
              <input
                type="tel"
                name="phone"
                className="form-control"
                id="phone"
                placeholder={this.props.showPlaceHolders ? 'Phone' : ''}
                required
                onChange={this.handleChange}
              />
            </div>
          </div>
        </div>

        <div className="quote-form-label-input-group">
          {this.props.showLabels ? (
            <FormLabel htmlFor="description">
              Tell us about your project
            </FormLabel>
          ) : null}
          <textarea
            className="form-control"
            name="description"
            id="description"
            placeholder={
              this.props.showPlaceHolders ? 'Tell us about your project' : ''
            }
            required
            rows="3"
            onChange={this.handleChange}
          />
        </div>

        <input type="hidden" name="form-name" value="contact-form" />
        <p hidden>
          <label>
            Don’t fill this out:{' '}
            <input name="bot-field" onChange={this.handleChange} />
          </label>
        </p>
        <input
          type="hidden"
          name="subject"
          className="form-control"
          id="subject"
          value={this.state.firstname}
          onChange={this.handleChange}
        />

        <div data-netlify-recaptcha />

        <ButtonContainer>
          <Button
            role="submit"
            type="submit"
            color="primary"
            className="shadow-lg"
          >
            Get FREE Estimate
          </Button>
        </ButtonContainer>
      </form>
    )
  }
}
